import reportRepository from "@/repository/reportRepository";
import salonAreaRepository from "@/repository/salonAreaRepository";
import salonRepository from "@/repository/salonRepository";

const repositories = {
    report: reportRepository,
    salonArea: salonAreaRepository,
    salon: salonRepository,
}

export const RepositoryFactory = {
    get: name => repositories[name]
}