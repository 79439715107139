<template>
    <a-layout-content class="Dashboard">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="TestCompleteReport" :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px 25px', padding: '15px' }">
            <div class="header">
                <h1>{{ this.$route.name }}</h1>
            </div>
            <a-divider/>
            <div class="report">
                <div class="report__filter">
                    <div class="filter-item">
                        <span><a-icon type="clock-circle" /> Thời gian: </span>
                        <a-range-picker
                                :locale="locale"
                                :defaultValue="[startDate, currentDate]"
                                @change="onChange"
                                format="YYYYMMDD"
                        />

                        <a-select :showSearch="true" :filterOption="filterOption" placeholder="Chọn quản lý vùng" style="min-width: 180px; margin-left: 20px" @change="onSelectSalonAreaChange">
                            <a-select-option key="all" value="all">Chọn tất cả vùng</a-select-option>
                            <a-select-option v-for="(item, index) in getSalonAreas" :key="index" :value="JSON.stringify(item)">{{ item.fullName }}</a-select-option>
                        </a-select>

                        <a-select v-model="selectedSalon" defaultValue="all" :disabled="disableSelectSalon" :showSearch="true" :filterOption="filterOption" placeholder="Chọn ds salon" style="min-width: 150px; margin-left: 20px" @change="onSelectSalonChange">
                            <a-select-option key="all" value="all">Chọn tất cả salon</a-select-option>
                            <a-select-option v-for="item in salonSelects" :key="item.id" :value="item.id">{{ item.shortName }}</a-select-option>
                        </a-select>

                        <a-button style="margin-left: 20px" type="primary" @click="handleGetReport" icon="export">Xem dữ liệu</a-button>
                    </div>
                </div>
                <div class="header__action">
                    <export-excel class="btn btn-default" :data="data" :fields="excelFields" worksheet ="Báo cáo tỉ lệ quét app trải nghiệm khách hàng" name="bao-cao-ti-le-quet.xls">
                        <a-button style="margin-left: 20px" type="primary" icon="export">Xuất file Excel</a-button>
                    </export-excel>
                </div>
            </div>
            <a-divider/>
            <div style="background-color: #ececec; padding: 20px;">
                <a-row :gutter="16">
                    <a-col :span="4">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="Tổng số bill quét" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{totalAllScan}}</p>
                        </a-card>
                    </a-col>
                    <a-col :span="4">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="Tổng số bill" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{totalAllBill}}</p>
                        </a-card>
                    </a-col>
                    <a-col :span="4">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="Tỉ lệ quét trung bình" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{avgPercent}}%</p>
                        </a-card>
                    </a-col>
                    <a-col :span="4">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="Tổng survey hoàn thành" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{totalAllSurveyCompleted}}</p>
                        </a-card>
                    </a-col>
                    <a-col :span="4">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="Tổng survey cần lấy" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{totalAllSurvey}}</p>
                        </a-card>
                    </a-col>
                    <a-col :span="4">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="Tỉ lệ hoàn thành survey" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{avgSurveyPercent}}%</p>
                        </a-card>
                    </a-col>
                </a-row>
            </div>
            <a-divider/>
            <a-table :columns="columns" :dataSource="data" size="middle" bordered :rowKey="(record, index) => index" :loading="loading" :pagination="pagination">
                <div
                        slot="filterDropdown"
                        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                        style="padding: 8px"
                >
                    <a-input
                            v-ant-ref="c => searchInput = c"
                            :placeholder="`Tìm kiếm ${column.title}`"
                            :value="selectedKeys[0]"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="() => handleSearch(selectedKeys, confirm)"
                            style="width: 188px; margin-bottom: 8px; display: block;"
                    />
                    <a-button type="primary" @click="() => handleSearch(selectedKeys, confirm)" size="small" style="width: 90px; margin-right: 8px">Tìm kiếm</a-button>
                    <a-button @click="() => handleReset(clearFilters)" size="small" style="width: 90px">Reset</a-button>
                </div>
                <a-icon
                        slot="filterIcon"
                        slot-scope="filtered"
                        type="search"
                        :style="{ color: filtered ? '#108ee9' : undefined, fontWeight: filtered ? 'bold': undefined }"
                />
                <template slot-scope="text">
                    <div :key="col">
                        <template>{{text}}</template>
                    </div>
                </template>
                <template slot="correctPercent" slot-scope="text, record">
                    <div :key="record.email">
                        <a-tag color="red">{{text}}%</a-tag>
                    </div>
                </template>
            </a-table>
        </div>
    </a-layout-content>
</template>

<script>
    import moment from 'moment'
    import locale from '../../configs/datePickerLocale'
    import { mapGetters, mapActions } from 'vuex';
    import { RepositoryFactory } from "@/repository/RepositoryFactory";
    const reportRepository = RepositoryFactory.get('report');
    moment.locale("vi-vn")

    export default {
        name: "TestCompleteReport",
        data() {
            return {
                searchText: '',
                searchInput: null,
                locale,
                startDate: moment().startOf('month'),
                currentDate: moment(),
                salonIds: [],
                data: [],
                disableSelectSalon: true,
                selectedSalon: 'all',
                salonSelects: [],
                loading: false,
                pagination: {
                    pageSize: 15,
                    showTotal: (total, range) => `Hiển thị từ ${range[0]} - ${range[1]} / ${total} dòng`
                },
                excelFields: {
                    'Id': 'stylistId',
                    'Họ và tên': 'stylistName',
                    'Salon': 'salonName',
                    'Tổng bill quét': 'totalScan',
                    'Tổng bill': 'totalBill',
                    'Tỉ lệ quét': {
                        field: 'scanPercent',
                        callback: (value) => {
                            return `${value}%`
                        }
                    },
                    'Tổng survey hoàn thành': 'totalSurveyCompleted',
                    'Tổng survey cần lấy': 'totalSurvey',
                    'Tỉ lệ hoàn thành survey': {
                        field: 'surveyPercent',
                        callback: (value) => {
                            return `${value}%`
                        }
                    }
                },
                columns: [{
                    title: 'ID',
                    dataIndex: 'stylistId',
                    width: '5%',
                    key: 'stylistId',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon'
                    },
                    onFilter: (value, record) => record.stylistId.toString().toLowerCase().includes(value.toString().toLowerCase()),
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus();
                            }, 0);
                        }
                    },
                },{
                    title: 'Họ tên',
                    dataIndex: 'stylistName',
                    width: '20%',
                    key: 'stylistName',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon'
                    },
                    onFilter: (value, record) => record.stylistName.toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus();
                            }, 0);
                        }
                    },
                },{
                    title: 'Salon',
                    dataIndex: 'salonName',
                    width: '10%',
                    sorter: (a, b) => { return a.salonName.toUpperCase().localeCompare(b.salonName.toUpperCase())}
                },{
                    title: "Tỉ lệ quét",
                    children: [
                        {
                            title: 'Tổng bill quét',
                            dataIndex: 'totalScan',
                            width: '10%',
                        }, {
                            title: 'Tổng bill',
                            dataIndex: 'totalBill',
                            width: '10%',
                            sorter: (a, b) => { return a.totalBill - b.totalBill}
                        }, {
                            title: 'Tỉ lệ quét',
                            width: '10%',
                            dataIndex: 'scanPercent',
                            scopedSlots: { customRender: 'correctPercent' },
                            sorter: (a, b) => { return a.scanPercent - b.scanPercent}
                        }
                    ]
                }, {
                    title: "Tỉ lệ Survey",
                    children: [
                        {
                            title: 'Tổng survey hoàn thành',
                            dataIndex: 'totalSurveyCompleted',
                            width: '10%',
                        }, {
                            title: 'Tổng survey cần lấy',
                            dataIndex: 'totalSurvey',
                            width: '10%',
                            sorter: (a, b) => { return a.totalBill - b.totalBill}
                        }, {
                            title: 'Tỉ lệ hoàn thành survey',
                            width: '10%',
                            dataIndex: 'surveyPercent',
                            scopedSlots: { customRender: 'correctPercent' },
                            sorter: (a, b) => { return a.surveyPercent - b.surveyPercent}
                        }
                    ]
                }]
            }
        },
        computed: {
            ...mapGetters('staticData', [
                'getSalons', 'getSalonAreas'
            ]),
            totalAllScan() {
                if (this.data.length === 0) return 0;
                let sum = this.data.reduce((total, item) => {
                    return total + item.totalScan;
                }, 0);
                return sum;
            },
            totalAllBill() {
                if (this.data.length === 0) return 0;
                let sum = this.data.reduce((total, item) => {
                    return total + item.totalBill;
                }, 0);
                return sum;
            },
            avgPercent() {
                if (this.totalAllBill === 0) return 0;
                return Math.round( (this.totalAllScan / this.totalAllBill) *100);
            },
            totalAllSurveyCompleted() {
                if (this.data.length === 0) return 0;
                let sum = this.data.reduce((total, item) => {
                    return total + item.totalSurveyCompleted;
                }, 0);
                return sum;
            },
            totalAllSurvey() {
                if (this.data.length === 0) return 0;
                let sum = this.data.reduce((total, item) => {
                    return total + item.totalSurvey;
                }, 0);
                return sum;
            },
            avgSurveyPercent() {
                if (this.totalAllSurvey === 0) return 0;
                return Math.round( (this.totalAllSurveyCompleted / this.totalAllSurvey) *100);
            },
        },
        methods: {
            ...mapActions('authenticate',[
                'refreshToken'
            ]),
            moment,
            onChange(date) {
                this.startDate = date[0];
                this.currentDate = date[1];
            },
            async handleGetReport() {
                try {
                    this.loading = true;
                    let response = await reportRepository.testCompleteReport({
                        workDateStart: this.startDate.format("YYYY-MM-DD"),
                        workDateEnd: this.currentDate.format("YYYY-MM-DD"),
                        salonIds: this.salonIds
                    });

                    if (response.data.meta.success) {
                        this.data = response.data.data.map( item => {
                            return {...item, scanPercent:  Math.round(item.totalScan / item.totalBill * 100), surveyPercent:  Math.round(item.totalSurveyCompleted / item.totalSurvey * 100)}
                        });
                    }
                } catch (e) {
                    if (e.message.includes("401")) {
                        await this.refreshToken();
                        this.handleGetReport();
                    } else {
                        this.openNotification(e.message)
                    }
                } finally {
                    this.loading = false;
                }
            },
            filterOption(inputValue, options) {
                return options.componentOptions.children[0].text.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            },
            onSelectSalonChange(value) {
                this.salonIds = value === 'all' ? this.salonSelects.map(salon => salon.id):this.salonSelects.filter(salon => salon.id === value).map( salon => salon.id );
            },
            onSelectSalonAreaChange(value) {
                if (value === 'all') {
                    this.salonIds = [];
                    this.disableSelectSalon = true;
                } else {
                    this.selectedSalon = 'all';
                    let salonArea = JSON.parse(value);
                    this.salonSelects = this.getSalons.filter( salon => salonArea.salonIds.includes(salon.id));
                    this.salonIds = this.salonSelects.map(salon => salon.id);
                    this.disableSelectSalon = false;
                }
            },
            openNotification (message) {
                this.$notification.open({
                    message: message
                });
            },
            handleSearch(selectedKeys, confirm) {
                confirm();
                this.searchText = selectedKeys[0];
            },

            handleReset(clearFilters) {
                clearFilters();
                this.searchText = '';
            },
        },
    }
</script>

<style scoped>
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .header__action {
        display: flex;
        flex-direction: row;
    }
    .filter-item span {
        font-weight: bold;
    }
    .report {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>